import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
  ALERT_POSITIONS,
  ALERT_THEMES,
  ALERT_TIMEOUT,
  ALERT_TYPES,
  ALLOW_ALPHABETS_REGEX,
  ALLOW_ALPHANUM_REGEX,
  ASU_AUTH_DOMAIN,
  AUTH_HOST,
  DATE_FORMAT5,
  MAX_FILE_SIZE,
} from "../constants";
import DataHandler from "../services/data-handler";
import {
  cloneDeep,
  filter,
  find,
  includes,
  isEmpty,
  isEqual,
  has,
  findIndex,
  every,
} from "lodash";
import Cookies from "js-cookie";
import { ERROR_SOMETHING_WENT_WRONG } from "../config/web-service";
import APP_CONFIG from "../config/app-config";
import ENV_CONFIG from "../config/env-config";

// GET APP CONFIG ACCORDING TO CURRENT HOST
export const getAppConfig = () => {
  let appConfig = APP_CONFIG.default;
  const { host, search } = window.location;
  // get appconfig  through host
  const hostname = host.split(".")[0];
  const domain = hostname.split("-")[0];
  const config = APP_CONFIG[domain];
  if (config) {
    appConfig = config;
  } else {
    // get appconfig through url params
    if (search) {
      const searchParams = new URLSearchParams(search);
      const app = searchParams.get("app");
      const config = APP_CONFIG[app];
      if (config) {
        appConfig = config;
      }
    }
  }

  return appConfig;
};

// GET APP HOST DETAIL
export const getHostDetail = () => {
  const { hostname } = window.location;
  // set return value to true if you want to use project app
  if (hostname.includes("localhost")) return false;

  const isAsugpt = hostname.includes("asugpt");

  return !isAsugpt;
};

// GET APP ENVIRONMENT ACCORDING TO CURRENT HOST
export const getAppEnv = () => {
  const { hostname } = window.location;
  const isProjectApp = getHostDetail();
  const envConfig = isProjectApp ? ENV_CONFIG.projectapp : ENV_CONFIG.asugpt;
  let environment = envConfig.prod;

  if (hostname.includes("beta")) environment = envConfig.beta;
  if (hostname.includes("poc")) environment = envConfig.poc;

  if (hostname.includes("localhost")) environment = envConfig.poc;

  return environment;
};

// CHECK TOKEN EXPIRATION
const parseToken = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

// CHECK TOKEN EXPIRATION
const checkTokenExpiration = (token) => {
  const currentDate = new Date();
  const tokenDetail = parseToken(token);
  if (tokenDetail.exp * 1000 < currentDate.getTime()) {
    return false;
  }
  return true;
};

// CHECK IF USER IS AUTHENTICATED
export const getUserAuthentication = () => {
  const { hostname } = window.location;
  let cookie = window.location.hostname.split(".")[0];
  let token = Cookies.get(cookie);
  if (hostname === "localhost") token = process.env.REACT_APP_DEV_TOKEN;
  if (token) {
    return checkTokenExpiration(token);
  }
  return false;
};

// NAVIGATE TO LOGIN PAGE
export const navigateToLogin = () => {
  const { href, search, hostname } = window.location;
  let environment = getAppEnv();

  if (hostname === "localhost") {
    toastAlert("Please update your dev token in .env file", ALERT_TYPES.ERROR);
    return;
  }

  // set redirect url in localstorage
  if (search) localStorage.setItem("redirectUrl", search);

  // navigate to login
  const route = `${AUTH_HOST}/login?service=${environment.service}/${environment.appName}/?aid=${environment.appId}%26redirect=${href}`;
  window.location = route;
};

// SET ACCESS TOKEN
export const setAccessToken = (token) => {
  return new Promise((resolve) => {
    if (!token) return resolve(false);

    const isValid = checkTokenExpiration(token);
    if (!isValid) return resolve(false);

    const cookieName = window.location.hostname.split(".")[0];
    Cookies.remove(cookieName);
    Cookies.set(cookieName, token, { domain: ASU_AUTH_DOMAIN });

    setTimeout(() => resolve(true), 500);
  });
};

// GET CURRENT ACCESS TOKEN FROM WEB COOKIE
export const getCurrentAccessToken = () => {
  const { hostname } = window.location;
  let cookie = window.location.hostname.split(".")[0];
  let token = Cookies.get(cookie);
  if (hostname === "localhost") token = process.env.REACT_APP_DEV_TOKEN;
  return token;
};

// GET CURRENT REFRESH TOKEN FROM USER REDUCER
export const getCurrentRefreshToken = () => {
  let token = DataHandler.getStore().getState().user.data.refresh_token;
  return token;
};

// CHECK IF URL IS VALID
export const isValidURL = (url) => {
  const re =
    /^https:\/\/[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(url);
};

// CHECK IF PROVIDED TIME FORMAT IS CORRECT
export const isTimeFormat = (time) => {
  const re =
    /^([1-9]|([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/[0-9]{4} [012]{0,1}[0-9]:[0-6][0-9]$/;
  let bol = re.test(time);
  return bol;
};

// CHECK IF EMAIL IS VALID
export const isEmailValid = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email.trim());
};

// CHECK IF PASSWORD LENGTH IS VALID
export const isPasswordValid = (password) => {
  let length = 5; // u can change pass length according to your requirement
  return password.length > length;
};

// CHECK IF NAME IS VALID
export const isValidName = (name) => {
  return /^[a-zA-Z ]*$/.test(name);
};

// CAPITALIZE FIRST LETTER OF STRING
export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
};

// FORMAT DATE ACCORDING TO PROVIDED FORMAT
export const getFormattedDateTime = (date, format = DATE_FORMAT5) => {
  if (date) return moment(date).format(format);
  return "";
};

// FORMAT UNIX DATE ACCORDING TO PROVIDED FORMAT
export const getFormattedUnixDateTime = (date, format = DATE_FORMAT5) => {
  if (date) return moment.unix(date).format(format);
  return "";
};

// FORMAT DATE ACCORDING TO PROVIDED FORMAT AND RETURN TO DATE OBJECT
export const getDateObjectFromString = (date, format) => {
  if (date) return moment(date, format).toDate();
  return "";
};

// CONVERT BYTES TO KB, MB, GB, TB, PB, EB, ZB, YB
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// CHECK IF MOBILE NUMBER IS VALID
export const isValidMobileNumber = (str) => {
  if (!str) return false;
  const isnum = /^\d+$/.test(str);

  if (str.length < 15 && str.length > 9 && isnum) {
    return true;
  }
  return false;
};

// CLONE ARRAY
export const cloneDeepItem = (array) => cloneDeep(array);

// FIND OBJECT FROM ARRAY
export const findDataFromArray = (array, mObj) => find(array, mObj);

// CHECK IF ARRAY HAS VALUE
export const isArrayIncludesValue = (array, value) => includes(array, value);

// CHECK IF VALUES ARE EQUAL
export const areValuesEqual = (objA, objB) => isEqual?.(objA, objB);

// CHECK IF VALUE IS EMPTY
export const isEmptyValue = (value = false, placeholdertext = true) => {
  return isEmpty(value) ? placeholdertext : value;
};

// EXCLUDE OBJECT FROM ARRAY BY ID
export const excludeIdFromArray = (mArr, id) =>
  filter(mArr, (item) => item.id !== id);

// EXCLUDE VALUE FROM ARRAY
export const excludeValueFromArray = (mArr, value) =>
  filter(mArr, (item) => item !== value);

// FILTER ARRAY BY FUNCTION
export const filterArray = (array, func) => filter(array, func);

// CHECK IF ARRAY DATA CONTACT ID
export const doesArrayContainsParticularId = (array, mId) => {
  if (find(array, { id: mId })) return true;
  else return false;
};

// CHECK IF STRING HAS ONLY WHITE SPACE
export const isOnlyWhiteSpace = (str) => {
  return !str.trim();
};

// CHECK IF OBJECT HAS PROVIDED KEY
export const hasObjectWithKey = (mObj, key) => has(mObj, key);

// CHECK IF VALUES IS ACCORDING TO FUNCTION CONDITION
export const hasEvery = (mArr, _func) => every(mArr, _func);

// GET OBJECT INDEX IN ARRAY BY ID
export const getIndexOfObjFromArrayByID = (mArr, id) =>
  findIndex(mArr, (item) => item.id === id);

// DELETE OBJECT FROM ARRAY BY ID
export const deleteObjectFromArray = (arr, id) => {
  let arrToReturn = arr.filter((a) => a.id !== id);
  return arrToReturn;
};

// GENERATE RANDOM STRING
export const generateGuid = () => {
  const uuid = uuidv4();
  const uuidHex = uuid.replace(/-/g, "");
  return uuidHex;
};

// REPLACE MULTIPLE VALUES IN STRING BY OBJECT
export const replaceValInString = (string, replacements = {}) => {
  if (!string) return;
  const pattern = new RegExp(Object.keys(replacements).join("|"), "g");
  return string.replace(pattern, (matched) => replacements[matched]);
};

// GET FIRST LETTER FROM NAME
export const getLetterFromName = (string) => {
  let name = string || "";
  const words = name.split(" ");
  let initials = words[0].charAt(0);
  if (words.length > 1) initials = initials + words[1]?.charAt(0);
  return initials;
};

// CONVERT OBJECT TO FORM DATA
export const convertObjectToFormData = (obj) => {
  const formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // reader.onload = (e) => resolve(e.target.result);
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const convertBase64ToBlob = (base64, type = "audio/mp3") => {
  try {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  } catch (error) {
    console.error("Error decoding Base64:", error);
    return null;
  }
};

export const sliceBase64 = (base64, chunkSize = 1024 * 10) => {
  const chunks = [];
  let offset = 0;

  while (offset < base64.length) {
    const chunk = base64.slice(offset, offset + chunkSize);
    chunks.push(chunk);
    offset += chunkSize;
  }

  return chunks;
};

// ANTD FORM INPUT FIELDS RULES
export const inputFieldRule = ({
  name = "Name",
  requiredMessage,
  isRequired = true,
  isWhiteSpace = true,
  isEmail = false,
  emailError = "email address",
  isType = false,
  type,
  isMax = false,
  max = 60,
  isAlphabetsAndNumber = false,
  isAlphabets = false,
}) => {
  const validationRule = [];
  if (isRequired) {
    validationRule.push({
      required: true,
      message: requiredMessage ? requiredMessage : `${name} is required`,
    });
  }
  if (isWhiteSpace) {
    validationRule.push({
      whitespace: true,
      message: requiredMessage ? requiredMessage : `${name} is required`,
    });
  }
  if (isMax) {
    validationRule.push({
      max: max,
      message: `Maximum ${max} characters allowed`,
    });
  }
  if (isEmail) {
    validationRule.push({
      type: "email",
      message: `Invalid ${emailError.toLowerCase()}`,
    });
  }
  if (isType) {
    validationRule.push({
      type,
      message: `Invalid ${name.toLowerCase()}`,
    });
  }
  if (isAlphabetsAndNumber) {
    validationRule.push({
      pattern: ALLOW_ALPHANUM_REGEX,
      message: `Invalid ${name.toLowerCase()}`,
    });
  }
  if (isAlphabets) {
    validationRule.push({
      pattern: ALLOW_ALPHABETS_REGEX,
      message: `Invalid ${name.toLowerCase()}`,
    });
  }
  return validationRule;
};

// ANTD FORM AUTOCOMPLETE FIELDS RULES
export const autoCompleteFieldRule = ({
  value,
  name = "",
  isRequired = true,
  isWhiteSpace = true,
  isEmail = false,
  isMax = false,
  max = 80,
}) => {
  if (isRequired && !value) {
    return Promise.reject(new Error(`${name} is required`));
  }
  if (typeof value === "string") {
    if (isWhiteSpace && isOnlyWhiteSpace(value)) {
      return Promise.reject(new Error(`${name} is required`));
    }
    if (isMax && value.length > max) {
      return Promise.reject(new Error(`Maximum ${max} characters allowed`));
    }
    if (isEmail && !isEmailValid(value)) {
      return Promise.reject(new Error(`Invalid ${name}`));
    }
  }
  return Promise.resolve();
};

// UPLOAD IMAGE VALIDATION
export const promptFilesValidation = (
  file,
  checkExtension = true,
  setError = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  }
) => {
  let res = true;
  const isValid = /(\.png|\.jpg|\.jpeg|\.doc|\.docx|\.pdf|\.txt)$/i;

  if (checkExtension && isValid.exec(file)) {
    setError("Please upload valid file!");
    res = false;
  }
  const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
  if (!isLt2M) {
    res = false;
    setError(`${file.name} must be smaller than ${MAX_FILE_SIZE}MB!`);
  }

  return res;
};

export const fileValidation = (
  file,
  checkExtension = true,
  setError = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  }
) => {
  let res = true;
  const isValid =
    /(\.png|\.jpg|\.jpeg|\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;

  if (checkExtension && isValid.exec(file)) {
    setError("Please upload valid file!");
    res = false;
  }
  const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
  if (!isLt2M) {
    res = false;
    setError(`${file.name} must be smaller than ${MAX_FILE_SIZE}MB!`);
  }

  return res;
};

// UPLOAD IMAGE VALIDATION
export const imageValidation = (
  file,
  checkExtension = true,
  checkLimit = true,
  setError = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  }
) => {
  let res = true;
  const isValidExtension = /\.(png|jpg|jpeg)$/i.test(file.name);

  if (checkExtension && !isValidExtension) {
    setError("You can only upload JPG/PNG file!");
    res = false;
  }

  if (checkLimit && file.size / 1024 / 1024 >= 4) {
    res = false;
    setError("Image must be smaller than 4MB!");
  }

  return res;
};

// UPLOAD DOCUMENT VALIDATION
export const documentValidation = (
  file,
  checkExtension = true,
  setError = (error) => {
    toastAlert(error, ALERT_TYPES.ERROR);
  }
) => {
  let res = true;
  const isDoc =
    /(\.csv|\.eml|\.msg|\.epub|\.xlsx|\.html|\.htm|\.md|\.org|\.odt|\.pdf|\.txt|\.text|\.log|\.pptx|\.rst|\.rtf|\.tsv|\.docx|\.xml|\.json|\.web|\.js|\.py|\.java|\.cpp|\.cc|\.cxx|\.c|\.cs|\.php|\.rb|\.swift|\.ts|\.go)$/i.test(
      file.name
    );
  if (checkExtension && !isDoc) {
    setError("You can only upload doc file!");
    res = false;
  }
  const isLt5M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
  if (!isLt5M) {
    res = false;
    setError(`${file.name} must be smaller than ${MAX_FILE_SIZE}MB!`);
  }

  return res;
};

// CUSTOM ALERT
// https://fkhadra.github.io/react-toastify/introduction/
export const toastAlert = (
  message = ERROR_SOMETHING_WENT_WRONG,
  type = ALERT_TYPES.SUCCESS,
  position = ALERT_POSITIONS.TOP_Right,
  duration = ALERT_TIMEOUT,
  closeOnClick = true,
  pauseOnHover = false,
  theme = ALERT_THEMES.LIGHT,
  draggable = false,
  isProgressBar = false
) => {
  toast[type](message, {
    position: position,
    autoClose: duration,
    hideProgressBar: isProgressBar,
    closeOnClick: closeOnClick,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    theme: theme,
  });
};
