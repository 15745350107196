import React, { useState, useEffect } from "react";
import { Checkbox, Tooltip } from "antd";
import { Button, Loader, Modal } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faFile,
  faImage,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { Icons } from "../../../../../theme";
import {
  convertObjectToFormData,
  toastAlert,
  documentValidation,
} from "../../../../../utils";
import { CustomDispatch } from "../../../../../helpers";
import {
  generateUploadURLRequest,
  uploadMediaRequest,
} from "../../../../../redux/slicers/general";
import { useSelector } from "react-redux";
import { TOAST_MESSAGES } from "../../../../../constants";
import "./styles.scss";

const MediaLibrary = ({
  preview = true,
  previewHandler,
  selectedMedia,
  selectMedia,
  deleteMediaToggle,
  getMediaListing,
  isMediaLoading,
}) => {
  // STATES
  const [tempSelectedMedia, setTempSelectedMedia] = useState([]);
  const [uploadedFiles, setuploadedFiles] = useState(0);
  const [totalFiles, settotalFiles] = useState(0);

  // CUSTOM DISPATCU
  const [uploadMedia, uploadLoader] = CustomDispatch(uploadMediaRequest);
  const [generateUrl, generateLoader] = CustomDispatch(
    generateUploadURLRequest
  );

  // CONST VALS
  const isLoading = uploadLoader || generateLoader;
  const isUpdated = tempSelectedMedia.join("") !== selectedMedia.join("");

  // REDUX DATA
  const { media } = useSelector(({ general }) => general);

  // HANDLERS
  const closeHandler = () => {
    previewHandler();
    settotalFiles(0);
    setuploadedFiles(0);
  };
  const uploadMediaHandler = (data, files) => {
    settotalFiles(data.length);

    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.url;
      delete object.name;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        otherkeys: { url: filedata.url },
        payload,
        success: () => {
          setuploadedFiles((uploadedFiles) => uploadedFiles + 1);
        },
      });
    });
  };
  const generateUrlHandler = (files) => {
    const payload = {
      files: files.map((item) => item.name),
      client_method: "upload",
    };
    generateUrl({
      payload,
      success: (data) => {
        uploadMediaHandler(data, files);
      },
    });
  };
  const selectUploadMediaHandler = (e) => {
    const files = e.target?.files;
    const validFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (documentValidation(file)) {
        validFiles.push(file);
      }
    }
    validFiles.length >= 1 && generateUrlHandler(validFiles);
  };
  const deleteMediaHandler = (e, media) => {
    e.stopPropagation();
    const temp = [...tempSelectedMedia];
    const index = temp.findIndex((item) => item === media.name);
    if (index > -1) temp.splice(index, 1);
    setTempSelectedMedia(temp);
    selectMedia(temp);
    deleteMediaToggle([media.name], true);
  };
  const handleDeleteSelectedMedia = () => {
    const files = [...tempSelectedMedia];
    setTempSelectedMedia(files);
    deleteMediaToggle(files, true);
    setTempSelectedMedia([]);
  };
  const handleMediaSelect = (media) => {
    const temp = [...tempSelectedMedia];
    const index = temp.findIndex((item) => item === media);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(media);
    }
    setTempSelectedMedia(temp);
  };
  const handleSelectAllMedia = () => {
    selectMedia(tempSelectedMedia);
    setTempSelectedMedia([]);
    closeHandler();
  };

  // HOOKS
  useEffect(() => {
    setTempSelectedMedia(selectedMedia);
  }, [selectedMedia]);

  useEffect(() => {
    let interval;
    const handleMediaChange = () => {
      const pendingmedia = media?.filter((item) => item.isPending).length;
      if (pendingmedia > 0) {
        interval = setInterval(() => {
          getMediaListing(true);
        }, 10000); // 10 seconds
      }
    };
    handleMediaChange();
    return () => interval && clearInterval(interval);
  }, [media]);

  useEffect(() => {
    if (uploadedFiles >= 1 && uploadedFiles >= totalFiles) {
      toastAlert(TOAST_MESSAGES.FILE_UPLOAD_SUCCESS);
      setuploadedFiles(0);
      getMediaListing();
    }
  }, [uploadedFiles, totalFiles]);

  return (
    <Modal
      width={800}
      className="media-library-wrapper"
      open={preview}
      openHandler={closeHandler}
      title="Media Library"
    >
      <p className="tagline">
        <span>Supported Filetypes:</span> Documents (e.g., DOCX, PDF, etc)
      </p>
      <div className="top-box">
        <div className="search-box">
          <FontAwesomeIcon icon={faSearch} />
          <input type="text" placeholder="Search here..." />
        </div>
        <Button
          invertedTheme
          className="upload-box"
          isLoading={isLoading}
          loaderColor="#000"
        >
          <input type="file" multiple onChange={selectUploadMediaHandler} />
          Upload Media
        </Button>
      </div>
      <div className="media-listing">
        <div className="listing-header">
          <span className="name">Name</span>
          <span className="upload">Uploaded At</span>
          {/* <span className="type">Type</span> */}
          <span className="size">Size</span>
          <span className="status">Search status</span>
        </div>
        {isMediaLoading ? (
          <Loader height={250} />
        ) : media.length >= 1 ? (
          media.map((item, index) => (
            <div
              role="button"
              tabIndex={index}
              className="listing-item"
              key={index}
              onClick={() => handleMediaSelect(item.name)}
              onKeyDown={() => handleMediaSelect(item.name)}
            >
              <Checkbox
                className="item-checkbox"
                checked={tempSelectedMedia.includes(item.name)}
              />
              <span className="name">
                <FontAwesomeIcon icon={item.isImage ? faImage : faFile} />
                {item.name}
              </span>
              <span className="upload">{item.uploadDate}</span>
              <span className="size">{item.size}</span>
              <span className="status">
                <Tooltip title={item.status}>
                  <FontAwesomeIcon
                    style={{ color: item.statusColor }}
                    icon={item.statusIcon}
                  />
                </Tooltip>
                <Tooltip title="Delete media">
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="delete-btn"
                    onClick={(e) => {
                      deleteMediaHandler(e, item);
                    }}
                  />
                </Tooltip>
              </span>
              {/* <span className="token">
                {item.token}
                <Tooltip title="Delete media">
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="delete-btn"
                    onClick={(e) => {
                      deleteMediaHandler(e, item);
                    }}
                  />
                </Tooltip>
              </span> */}
            </div>
          ))
        ) : (
          <div className="no-media-wrapper">
            <img src={Icons.NoMedia} alt="" />
            <h4>No Media Uploaded</h4>
          </div>
        )}
      </div>
      {(tempSelectedMedia.length >= 1 || isUpdated) && (
        <div className="bottom-box">
          {tempSelectedMedia.length >= 1 && (
            <Button onClick={handleDeleteSelectedMedia}>
              Delete selected files
            </Button>
          )}
          {isUpdated && (
            <Button onClick={handleSelectAllMedia}>Save changes</Button>
          )}
        </div>
      )}
    </Modal>
  );
};

export default MediaLibrary;
